// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-dialog {
  margin: 0; }

.custom-modal .modal-content {
  width: auto;
  background-color: #F5F5F5;
  border: none;
  border-radius: 20px; }

.modal-sm {
  width: auto;
  max-width: 50%; }

.modal-body-content {
  display: flex;
  flex-direction: row; }

.left-section {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center; }
  .left-section img {
    max-width: 100%;
    height: auto; }

.right-section {
  flex: 1 1;
  display: flex;
  flex-direction: column; }
  .right-section .callback-title {
    color: black;
    font-weight: 600;
    font-size: 17px;
    margin-left: 2px; }
  .right-section .phone-input {
    padding: 10px;
    border-radius: 20px; }
  .right-section .error-msg {
    color: red;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 2px; }
  .right-section .send-request-button {
    margin-top: 20px;
    border-radius: 20px;
    border-width: 0;
    background-color: #f5b100;
    font-weight: 500; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/shop-now-modal.scss"],"names":[],"mappings":"AAAA;EACI,SAAS,EAAA;;AAGX;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,mBAAmB,EAAA;;AAGrB;EACE,WAAW;EACX,cAAc,EAAA;;AAGhB;EACE,aAAa;EACb,mBAAmB,EAAA;;AAGrB;EACE,SAAO;EACP,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EAJzB;IAQI,eAAe;IACf,YAAY,EAAA;;AAIhB;EACE,SAAO;EACP,aAAa;EACb,sBAAsB,EAAA;EAHxB;IAMI,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,gBAAgB,EAAA;EATpB;IAaI,aAAa;IACb,mBAAmB,EAAA;EAdvB;IAkBI,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,gBAAgB,EAAA;EArBpB;IAyBI,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,gBAAgB,EAAA","sourcesContent":[".custom-modal .modal-dialog {\n    margin: 0;\n  }\n  \n  .custom-modal .modal-content {\n    width: auto;\n    background-color: #F5F5F5;\n    border: none;\n    border-radius: 20px;\n  }\n  \n  .modal-sm {\n    width: auto;\n    max-width: 50%;\n  }\n  \n  .modal-body-content {\n    display: flex;\n    flex-direction: row;\n  }\n  \n  .left-section {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    \n\n    img {\n      max-width: 100%;\n      height: auto;\n    }\n  }\n  \n  .right-section {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n\n    .callback-title {\n      color: black;\n      font-weight: 600;\n      font-size: 17px;\n      margin-left: 2px;\n    }\n  \n    .phone-input {\n      padding: 10px;\n      border-radius: 20px;\n    }\n\n    .error-msg {\n      color: red;\n      font-size: 12px;\n      margin-top: 10px;\n      margin-left: 2px;\n    }\n  \n    .send-request-button {\n      margin-top: 20px;\n      border-radius: 20px;\n      border-width: 0;\n      background-color: #f5b100;\n      font-weight: 500;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
