export { default as blogOneImg } from "./BANNER 2 copy.jpg";
export { default as blogTwoImg } from "./1Z6A2929 copy.jpg";
export { default as blogThreeImg } from "./Banner03.jpg";
export { default as penIcon } from "./pen.png";
export { default as calenderIcon } from "./calender.png";
export { default as slider1 } from "./slider-1.jpg";
export { default as slider2 } from "./slider-2.jpg";
export { default as shipping } from "./shipping.png";
export { default as premiumQuality } from "./quality-icon-red.png";
export { default as refund } from "./refund.png";
export { default as support } from "./support.png";
export { default as logo } from "./balonkun-logo.png";
export { default as newLogo } from "./balonkun-logo.png";
export { default as clientImg } from "./client-img.png";
export { default as cartIcon } from "./cart-icon.png";
export { default as heartIcon } from "./heart.png";
export { default as wishlistIcon } from "./wishlist.png";
export { default as barsIcon } from "./bars.png";
export { default as delIcon } from "./del-icon.png";
export { default as account } from "./account.png";
export { default as saving } from "./saving.png";
export { default as support1 } from "./support1.png";
export { default as fbIcon } from "./fb-icon.png";
export { default as googleIcon } from "./google-icon.png";
export { default as linkedinIcon } from "./linkedin-icon.png";
export { default as loaderGif } from "./spinner.gif";
export { default as message } from "./message-icon.png";
export { default as fourWheeler } from "./four-wheeler.svg";
export { default as twoWheeler } from "./two-wheeler.svg";
export { default as TwoWheeler } from "./scooter.png";
export { default as FourWheeler } from "./car.png";
export { default as orderPic } from "./order-pic.png";
export { default as locationIcon } from "./location.png";
export { default as star1 } from "./star1.png";
export { default as star2 } from "./star2.png";
export { default as star3 } from "./star3.png";
export { default as star4 } from "./star4.png";
export { default as star5 } from "./star5.png";
export { default as star6 } from "./star6.png";
export { default as star7 } from "./star7.png";
export { default as star8 } from "./star8.png";
export { default as star9 } from "./star9.png";
export { default as star10 } from "./star10.png";
export { default as placeholder } from "./placeholder.png";
export { default as img1 } from "./img1.jpg";
export { default as img2 } from "./img2.jpg";
export { default as img3 } from "./img3.jpg";
export { default as img4 } from "./img4.jpg";
export { default as img5 } from "./img5.jpg";
export { default as img6 } from "./img6.jpg";
export { default as img7 } from "./img7.jpg";
export { default as img8 } from "./img8.jpg";
export { default as img9 } from "./img9.jpg";
export { default as howItWorks } from "./how-its-work-banner.jpg";
export { default as imageNotAvailable } from "./image-not-available.png";
export { default as sadIcon } from "./sadIcon.png";
export { default as loaderImage } from "./loader-image.png";
export { default as userImage1 } from "./user1.jpg";
export { default as userImage2 } from "./user2.jpg";
export { default as divider } from "./divider.svg";
export { default as maintenance } from "./maintenance.jpg"
export { default as emptyCart } from "./empty-cart.png"
export { default as map } from "./map.png"
export { default as noData } from "./noData.svg"
export { default as shopNow } from "./shopNow.png";
export { default as review1 } from "./reviewers/18-Travels.jpeg"
export { default as review2 } from "./reviewers/aseem-jain.png"
export { default as review3 } from "./reviewers/Atul-Kumar-Saurabh.jpeg"
export { default as review4 } from "./reviewers/Dolly-singh.png"
export { default as review5 } from "./reviewers/Jagan-Reddy.jpeg"
export { default as review6 } from "./reviewers/Pankaj-Mishra.jpeg"