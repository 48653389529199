export const NAVBAR_LIST = {
  SEAT_COVERS: "Seat Covers",
  MATS: "Mats",
  ACCESSORIES: "Accessories",
  WHY_AUTOFORM: "Why Autoform",
  BLOGS: "Blogs"
};

export const CONTACT_INFO = {
  phone1: "+91 9278411411/",
  phone2: "+91 120 4247861",
  email: "marketing@autoformindia.com"
}