// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: #f0f0f0; }

.skeleton {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: shimmer 1.5s infinite; }

.skeleton-text {
  width: 80%; }

@keyframes shimmer {
  0% {
    background-position: -200px 0; }
  100% {
    background-position: 200px 0; } }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/footer-skeleton.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;EACb,yBAAyB,EAAA;;AAG7B;EACI,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,gCAAgC,EAAA;;AAGpC;EACI,UAAU,EAAA;;AAGd;EACI;IACI,6BAA6B,EAAA;EAEjC;IACI,4BAA4B,EAAA,EAAA","sourcesContent":[".footer-skeleton {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 20px;\n    background-color: #f0f0f0;\n}\n\n.skeleton {\n    height: 20px;\n    background-color: #e0e0e0;\n    border-radius: 4px;\n    animation: shimmer 1.5s infinite;\n}\n\n.skeleton-text {\n    width: 80%;\n}\n\n@keyframes shimmer {\n    0% {\n        background-position: -200px 0;\n    }\n    100% {\n        background-position: 200px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
